import config from "../config/config.json";

export const login = async (username, password) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
                'name': username,
                'password': password
            }
        )
    };
    let res = await fetch(`${config.IES_URL}/ife/rest/v1/login`, requestOptions);
    if ((!res.ok) || (res.status >= 400)) {
        return {"success": false};
    }
    return {"success": true, "data": await res.json()};
}
export const kiosks = async (userData) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userData.jwt
        }
    };
    let res = await fetch(`${config.IES_URL}/ife/rest/v1/tenant/${userData.entity}/kiosk`, requestOptions);
    if ((!res.ok) || (res.status >= 400)) {
        return {"success": false};
    }
    return {"success": true, "data": await res.json()};
}