import {Link} from "react-router-dom"
const Home = () => {
  const start = () => {};
  return (
    <div className="shell">
      <h2>Infrafon Suite</h2>
      <div className={"grid"}>
        <a href={"https://app.element.io/#/login"} className={"appContainer"}>
          <div className={"flex-row"} style={{ marginBottom: "0.5rem" }}>
            <img
              src={"email.png"}
              alt={"message icon"}
              height={30}
              style={{ marginRight: "0.5rem" }}
            />
            <span className={"formHeading"}>Messages</span>
          </div>
          <span className={"text"}>powered by</span>
          <span className={"text font-weight-bold"}>matrix</span>
        </a>
        <Link to="/nfc" className={"appContainer"}>
          <div className={"flex-row"} style={{ marginBottom: "0.5rem" }}>
            <img
              src={"nfc.png"}
              alt={"nfc icon"}
              height={30}
              style={{ marginRight: "0.5rem" }}
            />
            <span className={"formHeading"}>NFC-Emulation</span>
          </div>
          <span className={"text"}>powered by</span>
          <span className={"text font-weight-bold"}>security patterns</span>
        </Link>
        <Link to="/scan" className={"appContainer"}>
          <div className={"flex-row"} style={{ marginBottom: "0.5rem" }}>
            <img
              src={"nfc.png"}
              alt={"nfc icon"}
              height={30}
              style={{ marginRight: "0.5rem" }}
            />
            <span className={"formHeading"}>NFC-Scan</span>
          </div>
          <span className={"text"}>powered by</span>
          <span className={"text font-weight-bold"}>security patterns</span>
        </Link>
        <a
          href={"https://cedalo.com/pro-streamsheets/"}
          className={"appContainer"}
        >
          <div className={"flex-row"} style={{ marginBottom: "0.5rem" }}>
            <img
              src={"streamsheets.png"}
              alt={"streamsheets"}
              height={30}
              style={{ marginRight: "0.5rem" }}
            />
            <span className={"formHeading"}>Streamsheets</span>
          </div>
          <span className={"text"}>powered by</span>
          <span className={"text font-weight-bold"}>cedalo</span>
        </a>
        <a href={"https://www.syook.com/"} className={"appContainer"}>
          <div className={"flex-row"} style={{ marginBottom: "0.5rem" }}>
            <img
              src={"tool.png"}
              height={30}
              alt={"syook localization"}
              style={{ marginRight: "0.5rem" }}
            />
            <span className={"formHeading"}>Localization</span>
          </div>
          <span className={"text"}>powered by</span>
          <span className={"text font-weight-bold"}>syook</span>
        </a>
      </div>
    </div>
  );
};

export default Home;
