import React, {useState, useEffect} from 'react';
import mqtt from 'mqtt';
import './NFCui.css'; // Ensure your CSS file is linked
import QRCodeScanner from '../components/QRCodeScanner'
import { FaQrcode } from 'react-icons/fa';
import AllocationWizardData from './AllocationWizardData'
const axios = require('axios');

// Function to check device state
function NFCui() {
    // State to handle connection status
    const [isConnected, setIsConnected] = useState(false);
    const [mqttClient, setMqttClient] = useState(null);
    const [statusMessage, setStatusMessage] = useState('Connecting to MQTT broker...');
    const [Location, setLocation] = useState('Security');
    const [shouldReconnect, setShouldReconnect] = useState(true); // Define shouldReconnect here
    const [isConnected1, setIsConnected1] = useState(false);
    const [receivedMessage, setReceivedMessage] = useState('');
    const [parsedMessage, setParsedMessage] = useState(null); // Declare parsedMessage state
    const [parseError, setParseError] = useState('');
    const [messageTopic, setMessageTopic] = useState(''); // State variable for the topic
    const [deviceId, setDeviceId] = useState('');
    const [devices, setDevices] = useState([]); // State to store the list of devices
    const [deviceName, setDeviceName] = useState('');
    const [workers, setWorkers] = useState({});
    const [appname, setAppname] = useState("id_card_v2");
    const [Mqtt, setMqtt] = useState([]);
    const [showManagement, setShowManagement] = useState(true);
    const [showTasksAndMessagesPopup, setShowTasksAndMessagesPopup] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmResult, setConfirmResult] = useState(null);
    const [selectedDevices, setSelectedDevices] = useState({});
    const [showAddDevicePopup, setShowAddDevicePopup] = useState(false);
    const [newDeviceId, setNewDeviceId] = useState('');
    const [newDeviceName, setNewDeviceName] = useState('');
    const [showReturnPopup, setShowReturnPopup] = useState(false);
    const [currentUser, setCurrentUser] = useState(null); // or some initial value
    const [userDataSaved, setUserDataSaved] = useState(false);
    const [messageSubject, setMessageSubject] = useState('This is the default message.');
    const [messageBody, setMessageBody] = useState('');
    const [messageFrom, setMessageFrom] = useState('30');
    const [tableData, setTableData] = useState([]);
    const [lastErrorMessage, setLastErrorMessage] = useState('');
    const [logMessages, setLogMessages] = useState([]);
    const [showAboutPopup, setShowAboutPopup] = useState(false);
    const [showAllocatePopup, setShowAllocatePopup] = useState(false);
    const [workerStatuses, setWorkerStatuses] = useState({});
    const [result, setResult] = useState('');// initial state as an empty object
    const [selectedDevice, setSelectedDevice] = useState('');
    const randomDeviceIds = ["device2"];
    const [showScanner, setShowScanner] = useState(false);
    const [isDeviceAdded, setIsDeviceAdded] = useState(false); // Track if a device is currently added
    const [isDeviceIdsReady, setIsDeviceIdsReady] = useState(false);
    const [VisitorId, setVisitorId] = useState('');
    const [aboutPopupData, setAboutPopupData] = useState({
        deviceId: '',
        deviceName: '',
        workerName: '',
        // ... other necessary fields
    });
    const [returnPopupData, setReturnPopupData] = useState({
        deviceId: '',
        deviceName: '',
        workerName: '',
        comments: ''
    });
    const openReturnPopup = () => {
        setReturnPopupData({...returnPopupData, comments: ''}); // Reset only comments or all fields if needed
        setShowReturnPopup(true);
    };
    const [allocatePopupData, setAllocatePopupData] = useState({
        deviceId: '',
        deviceName: '',
        workerName: '',
        tasks: []
    });
    const initialAboutPopupData = {
        deviceId: '',
        deviceName: '',
        workerName: '',
        comments: ''

        // ... other necessary fields
    };
    //////////////////////////////new.v1///////////////////////
    /**
     * Function to open the return device popup
     */
    let globalVisitorId = null;
    async function checkDeviceState(deviceId) {
        updateLog("check")
        const token= "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcwOTcxNDY1NCwianRpIjoiZWJjMWJhNDItYmY3YS00NzBiLWJmOTMtOTI4NzBkYmQyZTY1IiwibmJmIjoxNzA5NzE0NjU0LCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiQWJkdWxyYWhtYW5fZGVtby5WXzAifQ.uM6w3Uvydfak9me3FrD7Ze4gN8ZiQtZYnhBuevUEpvA";
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let res = undefined
        try {
            res = await fetch(`https://api.infrafon.cloud/ife/rest/v1/tenant/DemoEntity/device/${deviceId}`, requestOptions)
            const data = await res.json();
            updateLog(`Received data: ${JSON.stringify(data)}`);
            const visitorId = data.data.allocation.device_data.user.visitor;
            updateLog("visitor"+visitorId)
            if (data.data.state.current === "DEP_DEALLOCING") {
                popup("Device is in DEP_DEALLOCING state.");
                return false;
                // Handle the case where device is in DEP_DEALLOCING state
            }
            else  if (data.data.state.current === 'DEP_ALLOCED' || data.data.state.current === 'DEP_ALLOCING') {
                updateLog("Device is in DEP_ALLOCED state.");
                if ( globalVisitorId === null) {
                    globalVisitorId = visitorId;
                    setVisitorId(globalVisitorId);
                    setLocation('Greenhouse1')
                } else {
                    updateLog("No visitor ID present."+ VisitorId);
                }
                return true;
                // Handle the case where device is in DEP_DEALLOCING state
            }
            else {
                if (res.status === 404) {
                    popup('Infrafon device is not registered!')
                } else {
                    if (res.status >= 400) {
                        popup('Server communication error')
                    }
                }
            }

            // Handle unexpected device state

            if (!res.ok) {
                console.log('Server communication error')
                return false
            }
            if (res.status >= 400) {
                console.log('Server communication error')
                return false
            }
        }
        catch (e) {
            console.log('Server communication error')
            return false
        }

    }
//-----------------------------------------------------------------------------
    const [deviceIds, setDeviceIds] = useState([]); // Store fetched device IDs here
    /**
     Fetching Device IDs:
     The component fetches device IDs from an external API endpoint using the fetchDeviceIds function when the component mounts.
     It updates the state with the fetched device IDs and sets a flag (isDeviceIdsReady) to indicate that the device IDs are available.
     */
    // Fetch device IDs when the component mounts
    useEffect(() => {
        async function fetchDeviceIds() {
            const token= "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcwOTcxNDY1NCwianRpIjoiZWJjMWJhNDItYmY3YS00NzBiLWJmOTMtOTI4NzBkYmQyZTY1IiwibmJmIjoxNzA5NzE0NjU0LCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiQWJkdWxyYWhtYW5fZGVtby5WXzAifQ.uM6w3Uvydfak9me3FrD7Ze4gN8ZiQtZYnhBuevUEpvA";
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };

            try {
                const response = await fetch(`https://api.infrafon.cloud/ife/rest/v1/tenant/DemoEntity/device`, requestOptions);
                const data = await response.json();
                if(data && data.data) {
                    // Assuming 'data.data' contains your devices array
                    const ids = data.data.map(device => device.name);

                    setDeviceIds(ids);
                    setIsDeviceIdsReady(true);



                }
            } catch (error) {
                updateLog('Error fetching device IDs:', error);
            }
        }
        fetchDeviceIds();
    }, []);
    useEffect(() => {
        console.log("deviceIds has been updated", deviceIds);

    }, [deviceIds]);
    useEffect(() => {
        // Function to execute after setting tableData
        const updateTableImmediately = () => {
            // Perform any actions that need to be done immediately after setting tableData
            console.log('Table data has been updated:', tableData);
            // You can trigger any immediate actions here
        };

        // Call the function immediately after setting tableData
        updateTableImmediately();
    }, [tableData]);
///////////////////////////////new.v1//////////////////////////
    // ####################################################################

    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /***** ///  pop up and handels msg//    *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/
    // This should be defined at the global scope of your code.
    const hideAll = () => {
        setShowManagement(false);
        setShowTasksAndMessagesPopup(false);
        // ... set state to false for other divs
    };

    const hideEl = (divName) => {
        if (divName === 'management') setShowManagement(false);
        if (divName === 'tasksAndMessagesPopup') setShowTasksAndMessagesPopup(false);
        // ... other conditions for other divs
    };

    const showEl = (divName) => {
        if (divName === 'management') setShowManagement(true);
        if (divName === 'tasksAndMessagesPopup') setShowTasksAndMessagesPopup(true);
        // ... other conditions for other divs
    };

    const MessageModal = ({message, onClose}) => (
        <div className="modal">
            <p>{message}</p>
            <button onClick={onClose}>OK</button>
        </div>
    );

    const ConfirmModal = ({message, onConfirm, onCancel}) => (
        <div className="modal">
            <p>{message}</p>
            <button onClick={() => onConfirm(true)}>Yes</button>
            <button onClick={() => onConfirm(false)}>No</button>
        </div>
    );

    const popup = (msg) => {
        alert(msg); // Temporary for debugging

        setModalMessage(msg);
        setShowMessageModal(true);
        setLastErrorMessage(msg);
        // Improved log messages
        updateLog("Popup: Modal message set to: " + msg);
        updateLog("Popup: Show message modal set to true");
        updateLog("Popup: Last error message set to: " + msg);


    };

    const yesno = (msg) => {
        setModalMessage(msg);
        setShowConfirmModal(true);
    };

    const handleConfirmClose = (result) => {
        setConfirmResult(result);
        setShowConfirmModal(false);
        // Process the result as needed
    };

    const handleSaveUserData = (user) => {
        saveUserData(user);
        setUserDataSaved(true);
    };

//########################################################################

///                 mqtt connection                                 ////

    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /***** ///  mqtt connection//           *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/
    // Function to attempt MQTT connection
    const onLoginAttempt = () => {
        const options = {
            connectTimeout: 10000, // milliseconds
            username: 'Abdulrahman_dev',
            password: 'Welcome0!',
            reconnectPeriod: shouldReconnect ? 1000 : 0,
            // Enable SSL/TLS
            protocol: 'wss',
            port: 8443

        };

        // Corrected URL format
        const client = mqtt.connect('wss://mqtt.infrafon.cloud:8443/mqtt', options);
        setMqttClient(client);



        client.on('connect', () => {
            setIsConnected(true);
            console.log('Connected to MQTT broker');
            setStatusMessage('Connected to MQTT broker.');
            for (var i = 0; i < devices.length; i++) {
                mqtt_subscribe(client, devices[i].deviceId);
                updateLog("find device: Found - " + JSON.stringify(devices[i]));

            }


            // Here you can also subscribe to topics
        });

        client.on('message', (topic, message) => {
            const messageString = message.toString();
            setMessageTopic(topic);

            handleMqttMessage(topic, message,client);

            setReceivedMessage(messageString);
            updateLog("recived message "+messageString)

            // Check if the message is not empty and looks like JSON
            if (messageString.trim() && messageString.startsWith('{') && messageString.endsWith('}')) {
                try {
                    const json = JSON.parse(messageString);
                    setParsedMessage(json);
                    setParseError('');
                } catch (error) {
                    setParsedMessage(null);
                    setParseError('Error   parsing JSON: ' + error.message);
                }
            } else {
                setParsedMessage(null);
                setParseError('Received message is not valid JSON');
            }
        });


        client.on('error', (err) => {
            setIsConnected(false);
            setStatusMessage('Connection error: ' + err.message);
            console.error('MQTT connection error:', err);
            client.end();
        });


        client.on('close', () => {
            setIsConnected(false);
            setStatusMessage('Connection closed.');
            updateLog('MQTT connection closed.');
        });
        client.on('reconnect', () => {
            console.log('MQTT Client trying to reconnect');
        });

        client.on('offline', () => {
            setIsConnected(false);
            setStatusMessage('MQTT client is offline.');
            updateLog('MQTT client is offline.');
        });

        // Handle clean up on component unmount
        return () => {
            client.end();
        };
    };

    const mqtt_subscribe = (client, deviceId) => {
        try {
            client.subscribe('ife-int/device/msgs-up/' + deviceId);
            console.log('mqtt subscribe for ' + deviceId);
            setStatusMessage('mqtt subscribe for ' + deviceId);
            client.subscribe(`ife-int/device/msgs-up/${deviceId}`, { qos: 1 }, (err) => {
                if (err) {
                    console.error(`Failed to subscribe to ${deviceId}:`, err);
                } else {
                    console.log(`Subscribed to ${deviceId} successfully.`);
                }
            });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        return () => {
            // This cleanup function is called on component unmount
            mqttClient?.end();
            console.log('Disconnected MQTT on component unmount');
        };
    }, []);
    useEffect(() => {
        if (!isDeviceIdsReady) return;
        onLoginAttempt();

        // Clean up function to run when component unmounts
        return () => {
            setShouldReconnect(false); // Ensure we don't try to reconnect after unmounting
        };
    }, [isDeviceIdsReady, deviceIds]);
    const mqtt_onMsgRx = (msg) => {
        try {
            console.log("rx msg from " + msg.destinationName + " with content " + msg.payloadString);


            let d = JSON.parse(msg.payloadString);
            // do stuff with it
            // find device in table, error if not one of ours
            // device id is the last component of the topic name
            let deviceId = msg.destinationName.split('/').slice(-1)[0];

            setStatusMessage(' subscribe for ' + deviceId);
            //let device = findDevice(deviceId);
            let device = findDeviceIds(deviceId);
            if (device === null) {
                console.log("rx msg from " + deviceId + " but can't find it");
                return;
            }


        } catch (e) {
            console.log(e);
        }
    }
    const findDeviceIds = (deviceId) => {
        // Assuming deviceIds is an array of valid device IDs
        if (deviceIds.includes(deviceId)) {
            return deviceId; // or return the device object if you have more info
        }
        return null;
    };
    const handleMqttMessage = (topic, message,mqttClient) => {
        if (!mqttClient) {
            console.log("MQTT client is not initialized handleMqttMessage.",mqttClient);
        }
        else{console.log("initialized");}

        setTimeout(() => {
            const msg = message.toString(); // Convert message to string
            //updateLog("Received  message   from " + topic + " with content " + msg+"deviceIds"+deviceIds);

            let data;
            try {
                data = JSON.parse(msg);
            } catch (error) {
                console.error("Error parsing MQTT message:", error);
                return;
            }


            let deviceId = topic.split('/').slice(-1)[0];
            setDeviceId(deviceId); // Update the state with the extracted device ID
            if (!deviceIds.includes(deviceId)) {
                console.log("Received message from unknown device ID" +deviceId);
                return;
            }
            else{ console.log("Received message ");}
            // Find device using your findDevice function
            //let device = findDevice(deviceId);
            let device = findDeviceIds(deviceId);

            if (device === null) {
                console.log("Received message from " + deviceId + " but can't find it");

                return;
            }

            // Update device's last seen timestamp
            setDevices(devices => {
                return devices.map(device => {
                    if (device.deviceId === deviceId) {
                        return { ...device, lastSeenAt: new Date().toISOString() };
                    } else {
                        return device;
                    }
                });
            });
            // device.lastSeenAt = new Date().toISOString(); // Update with a nice date format
            updateLog("after visitor ID present."+ VisitorId);
            /*if (VisitorId === null) {
                device.currentLocation = 'Security';
                updateLog("device seccc "+VisitorId)

            } else {
                device.currentLocation = 'Greenhouse1';
            }*/


            // Check if the message is an 'app' type message
            console.log("before process"+device)
            processMqttData(device, data,mqttClient);
            if (data.app && data.app[appname] !== undefined) {
                let res = data.app[appname];
                // Process message replies
                res.msglist?.forEach(msg => {
                    if ('reply' in msg) {
                        process_msg_reply(deviceId, msg.id, msg.reply);
                    } else if ('del' in msg) {
                        process_msg_delete(deviceId, msg.id);
                    }
                });
                // Process task list updates

                // Process other types of requests and updates
                if (res.pause !== undefined) {
                    process_pause(deviceId);
                }
                if (res.resume_work !== undefined) {
                    process_resume(deviceId);
                }
                if (res.help !== undefined || res.help2 !== undefined) {
                    process_help(deviceId);
                }
                if (res.request !== undefined || res.request2 !== undefined) {
                    process_key_request(deviceId);
                }
                if (res.cancelkey !== undefined) {
                    process_cancel_key(deviceId);
                }
                if (res.cancelhelp !== undefined) {
                    process_cancel_help(deviceId);
                }
            } else if (data.app && data.app['nfckey'] !== undefined) {
                let res = data.app['nfckey'];
                // Similar processing for 'nfckey' updates
                // Repeat the logic as in the previous block, or customize as needed
            } else {
                console.log("Message has no pp data relevant to the app: " + JSON.stringify(data));
            }
        }, 1000);
    };
//##############################################################



//###############################################################

///              proces mqtt data                            ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /***** ///  proces mqtt data//          *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/

    const updateTableWithResult = (deviceId,result) => {
        setTableData(prevData => {
            const updatedTableData = prevData[deviceId] ? [...prevData[deviceId]] : [];
            if (updatedTableData.length > 0) {
                updatedTableData[updatedTableData.length - 1].result = result;
            } else {
                // Optionally handle the case where there is no data to update
                updatedTableData.push({
                    result: result,
                    // other data fields need to be filled as required
                    time: new Date().toISOString() // example additional field
                });
            }
            return { ...prevData, [deviceId]: updatedTableData };
        });
    };
    const processMqttData = (device, data,mqttClient) => {
        console.log("finally");
        if (!mqttClient) {
            console.log("MQTT client is not initialized processMqttData.",mqttClient);
        }

        if (data.app === undefined) {
            updateLog("Ignoring   non-app message: " + JSON.stringify(data));
            // Here you might want to update the device's last seen time or other attributes
            return;
        }
        else{console.log("i am here");}
        let resp1='';
        if (data.app.dev !== undefined) {
            console.log("")
            let nfcresp = data.app.dev.nfc;

            let worker = findWorkerFromDevice(deviceId);
            console.log("before mqtt"+mqttClient)
            if (nfcresp !== undefined) {
                if (nfcresp.emulating === false) {
                    let resp = 'key received';
                    resp1=resp;
                    //setResult(resp)
                    console.log("result23",resp);


                    if (nfcresp.last_error !== null) {
                        resp = nfcresp.last_error;
                        // setResult(resp)
                        setTableData(prevData => {
                            const updatedTableData = prevData[deviceId] ? [...prevData[deviceId]] : [];
                            if (updatedTableData.length > 0) {
                                // Assuming you want to update the result of the last row for the device
                                updatedTableData[updatedTableData.length - 1].result = resp;
                            }
                            console.log("error")
                            return {...prevData, [deviceId]: updatedTableData};
                        });
                    }
                    else if (nfcresp.last_emul_count > 0) {
                        resp = 'key received and used';
                        resp1=resp;
                        //setResult(resp)
                        setTableData(prevData => {
                            const updatedTableData = prevData[deviceId] ? [...prevData[deviceId]] : [];
                            if (updatedTableData.length > 0) {
                                // Assuming you want to update the result of the last row for the device
                                updatedTableData[updatedTableData.length - 1].result = resp;
                            }
                            return {...prevData, [deviceId]: updatedTableData};
                        });


                    }
                    console.log("Process NFC update -> " + resp + " derived from " + JSON.stringify(nfcresp));

                    process_key_result(deviceId, resp);
                    //const client = mqttClient;

                    console.log("after process",mqttClient)
                    // send back to clear the message and return to main page
                    ies_send_key_end(mqttClient, device);

                    // Here, you might want to call a function to handle the NFC result
                    // e.g., processNfcResult(deviceId, resp);
                } else {
                    console.log("Process NFC update -> NFC action ongoing: " + JSON.stringify(nfcresp));
                }
            }
        }
        console.log("result233",resp1);
        if (resp1!==''){
            setResult(resp1);
            updateTableWithResult(device, resp1);
            console.log("result2323",result);
        }

        // Update the devices array in the state
        // Adjust this as per your application's state management
        setDevices(prevDevices => prevDevices.map(d => d.deviceId === device.deviceId ? device : d));
    };

    const process_msg_reply = (deviceId, messageId, reply) => {
        console.log("msg " + messageId + " gets reply " + reply);
        let worker = findWorkerFromDevice(deviceId);
        if (worker === null) {
            return;
        }
        for (var i = 0; i < worker.messages.length; i++) {
            if (worker.messages[i].id === messageId) {
                worker.messages[i].reply = reply;
                worker.messages[i].replyAt = niceDate(new Date());
                addWorkerEvent(worker.name, "msgReply", "msg[" + messageId + "] reply [" + reply + "]");
                return;
            }
        }
        addWorkerEvent(worker.name, "msgReplyNF", "msg[" + messageId + "] NOT FOUND reply [" + reply + "]");
    };

    const process_msg_delete = (deviceId, messageId) => {
        console.log("msg " + messageId + " gets deleted by device user");
        let worker = findWorkerFromDevice(deviceId);
        if (worker === null) {
            return;
        }
        for (var i = 0; i < worker.messages.length; i++) {
            if (worker.messages[i].id === messageId) {
                worker.messages.splice(i, 1);
                addWorkerEvent(worker.name, "msgDelete", "msg[" + messageId + "]");
                return;
            }
        }
        addWorkerEvent(worker.name, "msgDeleteNF", "msg[" + messageId + "] NOT FOUND");
    };

    const process_pause = (deviceId) => {
        console.log("work paused");
        let worker = findWorkerFromDevice(deviceId);
        if (worker === null) {
            return;
        }
        worker.status = "paused";
        addWorkerEvent(worker.name, "pause", "none");
        addWorkerEvent(worker.name, "helpRequest", "none");
        setWorkerStatuses(prevStatuses => ({
            ...prevStatuses,
            [worker.name]: worker.status  // Assuming worker.name is the unique identifier
        }));


    };

    const process_resume = (deviceId) => {
        console.log("work resumed");
        let worker = findWorkerFromDevice(deviceId);
        if (worker === null) {
            return;
        }
        worker.status = "working[" + findCurrentTaskSubject(worker) + "]";
        addWorkerEvent(worker.name, "resume", "none");
        addWorkerEvent(worker.name, "helpRequest", "none");
        setWorkerStatuses(prevStatuses => ({
            ...prevStatuses,
            [worker.name]: worker.status  // Assuming worker.name is the unique identifier
        }));


    };

    const process_help = (deviceId) => {
        console.log("help requested");
        let worker = findWorkerFromDevice(deviceId);
        if (worker === null) {
            return;
        }
        worker.status = "needs help";
        addWorkerEvent(worker.name, "helpRequest", "none");
        addWorkerEvent(worker.name, "helpRequest", "none");
        setWorkerStatuses(prevStatuses => ({
            ...prevStatuses,
            [worker.name]: worker.status  // Assuming worker.name is the unique identifier
        }));


    };

    const process_key_request = (deviceId) => {
        updateLog("key requested");
        let worker = findWorkerFromDevice(deviceId);
        if (worker === null) {
            return;
        }
        worker.status = "requests a key";
        addWorkerEvent(worker.name, "helpRequest", "none");
        // setWorkerStatuses(worker.status);
        setWorkerStatuses(prevStatuses => ({
            ...prevStatuses,
            [worker.name]: worker.status  // Assuming worker.name is the unique identifier
        }));


    };
// Assuming you have a state for devices and it's an array

    const process_cancel_key = (deviceId) => {
        console.log("key request cancelled");
        let worker = findWorkerFromDevice(deviceId);
        if (worker === null) {
            return;
        }
        worker.status = "no key active";
        addWorkerEvent(worker.name, "helpCancel", "none");
        setWorkerStatuses(prevStatuses => ({
            ...prevStatuses,
            [worker.name]: worker.status  // Assuming worker.name is the unique identifier
        }));

    };

    const process_cancel_help = (deviceId) => {
        console.log("help cancelled");
        let worker = findWorkerFromDevice(deviceId);
        if (worker === null) {
            return;
        }
        worker.status = "working[" + findCurrentTaskSubject(worker) + "]";
        addWorkerEvent(worker.name, "helpCancel", "none");
        addWorkerEvent(worker.name, "helpRequest", "none");
        setWorkerStatuses(prevStatuses => ({
            ...prevStatuses,
            [worker.name]: worker.status  // Assuming worker.name is the unique identifier
        }));

    };

    const process_key_result = (deviceid, reply) => {
        console.log("last key sent gets reply " + reply);
        const worker = findWorkerFromDevice(deviceid);
        if (worker === null) {
            return;
        }
        worker.status = "no key active";
        const midx = worker.messages.length - 1;
        if (midx >= 0) {
            worker.messages[midx].reply = reply;
            worker.messages[midx].replyAt = niceDate(new Date());
            addWorkerEvent(worker.name, "keyResult", `reply [${reply}]`);
            return;
        }
        addWorkerEvent(worker.name, "keyReplyNF", `key sending NOT FOUND reply [${reply}]`);
        addWorkerEvent(worker.name, "helpRequest", "none");
        setWorkerStatuses(prevStatuses => ({
            ...prevStatuses,
            [worker.name]: worker.status  // Assuming worker.name is the unique identifier
        }));


    };


//##############################################################



//###############################################################

///              mqtt send data                              ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /***** ///  mqtt send data  //          *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/


    const mqtt_send_data = (client, deviceId, data) => {
        console.log("mqttdecive"+deviceId);

        try {


            let sd = JSON.stringify(data);
            console.log("MQTT Client:", client);
            console.log("MQTT data:", sd);

            if (client !== null) {
                client.publish("ife-int/device/msgs-down/" + deviceId, sd, {qos: 1});
                updateLog("sent " + sd + " to " + "ife-int/device/msgs-down/" + deviceId);
            } else {
                updateLog("no mqtt connection to send data to " + deviceId + " : " + sd);
            }
        } catch (e) {
            console.log(e);
            // Add any additional error handling as necessary
        }
    };

    const ies_allocate_device = (client, deviceid, devices) => {
        // Assuming findDevice is a function that searches in the 'devices' array
        let device = devices.find(d => d.deviceId === deviceid);
        if (!device) {
            console.log("device " + deviceid + " not existant so can't allocate in ies");
            return false;
        }
        let workername = device.allocatedTo;
        if (workername === null) {
            console.log("device " + deviceid + " not allocated so can't allocate in ies");
            return false;
        }
        let winfo = findWorker(workername);
        if (winfo === null) {
            console.log("can'f find worker " + workername + " to allocate in ies");
            return false;
        }

        let udata = {"data": {"workerid": workername, "tasklist": [], "msglist": []}};
        for (var i = 0; i < winfo.tasks.length; i++) {
            let t = winfo.tasks[i];
            // TODO tasks could define specific replies?
            udata.data.tasklist.push(
                {
                    "id": t.id,
                    "at": t.priority,
                    "selected": false,
                    "from": t.from,
                    "subj": t.subject,
                    "text": t.detail,
                    "icon_s": t.subject + "_100dp",
                    "icon_l": t.subject + "_256dp",
                    "b1": "Done",
                    "b1_i": "button_ok_100dp",
                    "b2": "Problem",
                    "b2_i": "button_problem_100dp",
                    "b3": "Refused",
                    "b3_i": "button_cancel_100dp"
                },
            );
        }
        let now = niceDate(new Date());
        for (var i = 0; i < winfo.messages.length; i++) {
            let m = winfo.messages[i];
            // TODO messages could define specific replies?
            udata.data.msglist.push(
                {
                    "id": m.id,
                    "at": now,
                    "from": m.from,
                    "subj": m.subject,
                    "text": m.body,
                    "b1": m.reply_1,
                    "b2": m.reply_2,
                    "b3": m.reply_3
                }
            );
        }

        // assume device already 'allocated', we just update the DV
        // Add actions to hide the system DV button, show the main widgets
        udata["actions"] = [{"a": "hideIW", "p": ["system", "refresh"]}, {
            "a": "showIW",
            "p": ["workerid", "photo", "request", "tasklist", "msgs", "help", "pause"]
        }, {"a": "gotoPage", "p": "1"}];

        let dreq = {"app": {}};
        dreq.app[appname] = udata;
        console.log("ies allocation of device : initialising with allocate request " + JSON.stringify(dreq));
        mqtt_send_data(deviceid, dreq);
        // Send 2nd message to refresh page/sound buzzer etc
        let areq = {
            "app": {
                "dev": {
                    "vibration": {"song": "V10S01V10"},
                    "buzzer": {"song": "bb01c-01"},
                    "led": {"song": "9991000010999100001099910"}
                }
            }
        };

        console.log("ies   allocation of device : alerting user " + JSON.stringify(areq));
        mqtt_send_data(deviceid, areq);

        return true;
    };

    const ies_send_msg = (client, deviceid, m) => {
        let msg = {"app": {}};
        msg.app[appname] = {"data": {"msglist": []}, "actions": []};
        let now = niceDate(new Date());
        msg.app[appname].data.msglist.push({
            "id": m.id,
            "at": now,
            "from": m.from,
            "subj": m.subject,
            "text": m.body,
            "b1": m.reply_1,
            "b2": m.reply_2,
            "b3": m.reply_3
        });
        msg.app[appname].actions.push({"a": "gotoWidgetDED", "p": {"w": "msglist", "item": m.id}});
        msg.app["dev"] = {
            "vibration": {"song": "V10S01V10"},
            "buzzer": {"song": "bb01"},
            "led": {"song": "99910"}
        };
        return mqtt_send_data(client, deviceid, msg);
    };

    const ies_send_key = (client, deviceid, m) => {
        if (!mqttClient) {
            console.log("MQTT client is not initialized in ies_send_key.",mqttClient);
        }
        else{console.log("initialized in ies_send_key");}
        let msg = {"app": {}};
        msg.app[appname] = {
            "data": {"keystatus": m.subject},
            "actions": [{"a": "gotoPage", "p": "waitingkey"}]
        };
        msg.app["dev"] = {
            "nfc": {"uid": "" + m.body, "emulDurationSecs": parseInt(m.from), "doEmul": true, "passive_enabled": false},
            "vibration": {"song": "V10"},
            "buzzer": {"song": "bb01"},
            "led": {"song": "99910"}
        };
        updateLog("sendin key UID " + m.body + " to device " + deviceid);
        return mqtt_send_data(client, deviceid, msg);
    };

    const ies_send_key_end = (client, deviceid) => {
        if (!mqttClient) {
            console.log("MQTT client is not initialized in ies_send_key_end.",mqttClient);
        }
        else{console.log("initialized in ies_send_key_end");}
        let msg = {"app": {}};
        msg.app[appname] = {
            "data": {"keystatus": "WAITING FOR KEY"},
            "actions": [{"a": "gotoPage", "p": "1"}]
        };
        msg.app["dev"] = {
            "vibration": {"song": "V10"},
            "buzzer": {"song": "bb01"},
            "led": {"song": "99910"}
        };
        updateLog("sending key nd to device " + deviceid);

        return mqtt_send_data(client, deviceid, msg);
    };
    const ies_add = (client, deviceid) => {
        let msg = {"app": {}};

        msg.app["dev"] = {
            "vibration": {"song": "V10"},
            "buzzer": {"song": "bb01"},
            "led": {"song": "99910"}
        };
        updateLog("added device" + deviceid);

        return mqtt_send_data(client, deviceid, msg);
    };

    const handleSendKey = (deviceid) => {
        if (!mqttClient) {
            console.log("MQTT client is not initialized in handlesendkey.",mqttClient);
        }
        else{console.log("initialized in handlesendkey");}

        updateLog("send 1")
        console.trace('handleSendKey was called');

        let deMessageSubject = messageSubject;
        let deMessageBody = messageBody;
        let deMessageFrom = messageFrom;
        let deMessageAt = niceDate(new Date());
        /*if (!deMessageSubject.trim()) {
            popup("Please enter a valid message (non-empty).");
            return false;
        }*/
        console.log(`handleSendKey called with deviceId: ${deviceid}`);
        console.log(`Current messageSubject: ${messageSubject}`);
        console.log(`Current messageBody: ${messageBody}`);
        console.log(`Current messageFrom: ${messageFrom}`);
        console.log(`Current VisitorId: ${VisitorId}`);
        if (!deMessageBody.trim()) {
            popup("Please choose an option.");
            return false;
        }

        if (!deMessageFrom.trim() || isNaN(deMessageFrom)) {
            popup("Please enter a valid Active Duration (non-empty, use just number).");
            return false;
        }

        let workername = VisitorId
        updateLog("Worker found"+workers);
        if (VisitorId !== null) {
            setMessageSubject('');
            setMessageBody('');

            // Update the table data
            setTableData(prevData => {
                const updatedTableData = prevData[deviceid] ? [...prevData[deviceid]] : [];
                updatedTableData.push({
                    time: deMessageAt, // or your own time format
                    message: deMessageSubject,
                    keyUID: deMessageBody,
                    duration: deMessageFrom,
                    result: ''// or the actual result based on your logic
                });
                return {...prevData, [deviceid]: updatedTableData};
            });


            let m = {
                "id": deMessageAt,
                "at": deMessageAt,
                "subject": deMessageSubject,
                "body": deMessageBody,
                "from": deMessageFrom,
                "reply_1": "OK",
                "reply_2": "?",
                "reply_3": "NOK",
                "reply": "",
                "replyAt": ""
            };

            updateLog("Worker found"+deviceid);
            console.log("handle mqtt",mqttClient)
            const client = mqttClient
            let device = devices.find(d => d.deviceId === deviceid);

            if (deviceid !== null) {
                // BW hacked to make sending message be sending key
                ies_send_key(client, deviceId, m);
            }
        } else {
            updateLog("Worker not found");
            // Handle the case where the worker is not found
            // Maybe show an error message to the user
        }

    }

    const ies_return_device = (client, deviceid) => {
        let device = devices.find(d => d.deviceId === deviceid);
        if (!device) {
            console.log("device " + deviceid + " not existant so can't return in ies");
            return false;
        }
        let udata = {"data": {"workerid": "", "tasklist": [], "msglist": []}};
        udata["actions"] = [
            {"a": "showIW", "p": ["system", "refresh"]}, {
                "a": "hideIW",
                "p": ["workerid", "photo", "request", "tasklist", "msgs", "help", "pause"]
            }, {"a": "gotoPage", "p": "1"}];
        let rdata = {"app": {}};
        rdata.app[appname] = udata;
        console.log("ies return of device : cleaning with mqtt request " + JSON.stringify(rdata));
        mqtt_send_data(client, deviceid, rdata);
        let areq = {
            "dev": {
                "vibration": {"song": "V10S01"},
                "buzzer": {"song": "bb01"},
                "led": {"song": "999100001099910"}
            }
        };
        console.log("ies allocation of device : alerting user " + JSON.stringify(areq));
        mqtt_send_data(client, deviceid, areq);
        return true;
    };
//##############################################################



//###############################################################

///              operation system                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /***** ///  operation system     //     *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/


    const loadUserData = (u) => {
        const userData = localStorage.getItem('taskmgr.persist.' + u);
        if (userData) {
            const {devices, workers} = JSON.parse(userData);
            setDevices(devices);
            setWorkers(workers);
        }
        // ... additional logic

    };

    const saveUserData = (u) => {
        const data = {devices, workers};
        localStorage.setItem('taskmgr.persist.' + u, JSON.stringify(data));
    };

//###############################################################
///                                 ///
//###############################################################

///              find device and worker                                     ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /***** ///  find device and worker //   *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/

    const findDevice = (deviceId) => {
        // updateLog("device length: " + devices.length);
        let b = devices
        //updateLog("find device: Found - " + deviceId);


        for (var i = 0; i < devices.length; i++) {
            //updateLog("Comparing deviceId:"+ deviceId+ "with devices[i].deviceId:"+ devices[i].deviceId);

            if (devices[i].deviceId === deviceId) {
                //   updateLog("find device: Found - " + JSON.stringify(devices[i]));


                return devices[i];
            }
            //updateLog("find device: Found - " + JSON.stringify(devices[i]));

        }
        //updateLog("find device " + deviceId);
        return null;
    }

    const findLastUpdatedDevice = () => {
        if (devices.length === 0) return null;

        return devices.reduce((lastUpdated, device) => {
            return (new Date(device.updatedAt) > new Date(lastUpdated.updatedAt)) ? device : lastUpdated;
        });
    };

    const findWorker = (name) => {
        return workers[name] || null;
    };

    const findWorkerFromDevice = (deviceid) => {
        const device = devices.find(d => d.deviceId === deviceid);
        if (!device) {
            updateLog("device " + deviceid + " not found");
            return null;
        }
        if (!VisitorId) {
            updateLog("device " + deviceid + " not allocated");
            return null;
        }
        return findWorker(VisitorId);
    };


    const findCurrentTaskSubject = (worker) => {
        for (var i = 0; i < worker.tasks.length; i++) {
            if (worker.tasks[i].id === worker.currentTask) {
                return worker.tasks[i].subject;
            }
        }
        return "idle";
    };



//##############################################################



//###############################################################

///              delete                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****       ///  delete     //         *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/
    const deleteDevices = () => {
        setDevices([]); // Clears the entire device list
        globalVisitorId = null;
        setIsDeviceAdded(false);
        setVisitorId='';
        setLocation = 'Security'
        saveUserData(currentUser); // Save the updated state after deletion.
        updateLog("All devices have been deleted.");


    };


//##############################################################



//###############################################################

///              add device                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****       ///  add device     //     *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/

    const addDeviceEvent = (deviceid, eventype, eventdetail) => {
        const deviceIndex = devices.findIndex(device => device.deviceId === deviceid);
        if (deviceIndex === -1) {
            console.log("failed to insert event for unknown device " + deviceid);
            return;
        }
        const updatedDevices = devices.map((device, index) =>
            index === deviceIndex ? {
                ...device,
                events: [...device.events, {"at": niceDate(new Date()), "type": eventype, "detail": eventdetail}]
            } : device
        );
        setDevices(updatedDevices);
    };

    const addWorkerEvent = (workername, eventype, eventdetail) => {
        const worker = workers[workername];
        if (!worker) {
            console.log("failed to insert event for unknown worker " + workername);
            return;
        }
        const updatedWorker = {
            ...worker,
            events: [...worker.events, {"at": niceDate(new Date()), "type": eventype, "detail": eventdetail}]
        };
        setWorkers({...workers, [workername]: updatedWorker});
    };

    const addDevice = (deviceid, nom) => {
        updateLog("addDevice: Function called" + nom);

        if (devices.some(device => device.deviceId === deviceid)) {
            updateLog("Existing devices:", devices);
            popup("device already exists");
            updateLog("device already exists");
            return;
        }
        /*if (devices.some(device => device.nom === nom)) {
            popup("device name already exists");
            updateLog("device name already exists");
            return;
        }*/
        // ... MQTT subscription and other logic
        const client = mqttClient
        const subscriptionSuccessful = mqtt_subscribe(client, deviceid);
        if (!subscriptionSuccessful) {
            updateLog("Device " + nom + " not found... but adding anyway");
        }
        updateLog("Device " + nom + " not found... but adding anyway");

        // Create a new device object
        const newDevice = {
            deviceId: deviceid,
            nom: nom,
            currentLocation: "Security",
            allocatedTo: null,
            allocatedAt: null,
            lastSeenAt: "never",
            events: [],
            status: ""
        };

        // Add the new device to the state
        setDevices([...devices, newDevice]);
        //updateLog("new Device " + deviceid + "kkk");
        updateLog("find device: adddevice - " + JSON.stringify(devices));


        let device = devices.find(d => d.deviceId === deviceid);

        if (deviceid !== null) {
            // BW hacked to make sending message be sending key
            ies_add(client, deviceId);
        }


        // Add an event for the new device
        addDeviceEvent(deviceid, "added", "");

        if (device === null) {
            updateLog("Received message from " + deviceId + " but can't find it");
            return;
        }
        else{
            updateLog("found")}

        // Save user data (assuming saveUserData is defined)
        saveUserData(currentUser);

    };

    const onAddDevice = () => {
        setNewDeviceId('');
        setNewDeviceName('');

        setShowAddDevicePopup(true);

    };

    const onAddOk = async () => {
        updateLog("onAddOk: Function called");

        let deviceId = newDeviceId;
        updateLog("onAddOk: Device ID after modification", deviceId);

        if (deviceId.length === 4) {
            deviceId = "240ac40a" + deviceId;
        }
        deviceId = deviceId.toLowerCase();

        if (deviceId.length !== 12) {
            updateLog("onAddOk: Device ID validation failed", deviceId);

            popup("Please enter your device ID as either exactly 4 or 12 hex digits");
            return;
        }
        ////////////////////new.v1//////////////////////
        const isDeviceOk = await checkDeviceState(deviceId);
        if (!isDeviceOk) {
            popup("Device is in DEP_DEALLOCING state, aborting add.");
            setShowAddDevicePopup(false);
            return; // Abort if device is not okay to add
        }
        ////////////////////new.v1//////////////////////
        const client = mqttClient
        if (deviceId !== null) {
            // BW hacked to make sending message be sending key
            ies_add(client, deviceId);
        }



        updateLog("onAddOk: Validations passed, closing popup and adding device");
        addDevice(deviceId, newDeviceName);

        setIsDeviceAdded(true);
        setShowAddDevicePopup(false);
        //setNewDeviceId('');
        //  setNewDeviceName('');
// Ensure addDevice is adapted to work with React state
    };

    const onAddCancel = () => {
        setShowAddDevicePopup(false);
        setShowScanner(false);
        setNewDeviceId('');
    };





//##############################################################



//###############################################################

///              Return device                                  ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****       ///  Return device  //     *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/

    const onReturn = (deviceid) => {
        updateLog("kk")
        const device = findDevice(deviceid); // Adapt findDevice to work with React state
        if (!device) {
            popup("device not found");
            return;
        }
        if (!device.allocatedTo) {
            popup("device not allocated!");
            return;
        }

        setReturnPopupData({
            deviceId: deviceid,
            deviceName: device.nom,
            workerName: device.allocatedTo,
            comments: ''
        });
        setShowReturnPopup(true);
    };

    async function onReturnOk(deviceId) {
        updateLog("Attempting to return the device");
        const token= "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcwOTcxNDY1NCwianRpIjoiZWJjMWJhNDItYmY3YS00NzBiLWJmOTMtOTI4NzBkYmQyZTY1IiwibmJmIjoxNzA5NzE0NjU0LCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiQWJkdWxyYWhtYW5fZGVtby5WXzAifQ.uM6w3Uvydfak9me3FrD7Ze4gN8ZiQtZYnhBuevUEpvA";
        const url = `https://api.infrafon.cloud/ife/rest/v1/tenant/DemoEntity/device/${deviceId}`; // Adjust URL as necessary
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                "operator": {"$set": {"state": "DEP_DEALLOCING"}}
            })
        };

        try {
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                const data = await response.json();

                updateLog(`Device return successful: ${JSON.stringify(data)}`);
                const client = mqttClient
                ies_add(client, deviceId);
                popup("Please return it from the device too!")
                setDevices([]); // Clears the entire device list
                globalVisitorId = null;
                setVisitorId='';
                // Save the updated state after deletion.
                updateLog("All devices have been deleted.");


                // Additional logic after successfully updating the device state
            } else {
                // Handle HTTP errors
                updateLog(`Failed to return device, server responded with status: ${response.status}`);
            }
        } catch (error) {
            // Handle network errors
            updateLog(`Error while attempting to return device: ${error}`);
        }

    }

//##############################################################



//###############################################################

///              on About                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****       ///  on About   //         *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/


    const onAbout = (deviceid) => {

        let device = findDevice(deviceid); // Ensure findDevice works with React state
        updateLog("about called" + deviceid)

        if (!device) {
            popup("device not found");
            updateLog("device not found" + deviceid)
            return;
        }

        if (!VisitorId) {
            popup("device not alloctaed");
            updateLog("device not alloctaed"+globalVisitorId);

            return;
        }


        setAboutPopupData({
            deviceId: deviceid,
            deviceName: deviceid,
            workerName: VisitorId,


            // ... populate other fields
        });

        setShowAboutPopup(true);

        if (showAddDevicePopup) {
            updateLog("working noooooooooooooo:");
            return;
        }

    };
    const leaveAbout = () => {
        setShowAboutPopup(false); // Assuming this state controls the about popup visibility
        setShowManagement(true); // Show the management section
    };
    const resetReturn = (deviceId) => {
        // ... existing logic ...

        // Reset related states
        setTableData([]);
        setMessageSubject('');
        setMessageBody('');
        setMessageFrom('');

        // ... any other necessary logic ...
    };



//##############################################################



//###############################################################

///              Additional functions                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****  ///  Additional functions //    *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/
    const padZero = (n) => {
        if (n < 0) {
            return n;
        }
        if (n < 10) {
            return '0' + n;
        }
        return '' + n;
    };
    const niceDate = (d) => {
        try {
            // Need 0 pad to make fixed-size fields
            return (
                padZero(d.getHours()) +
                ':' +
                padZero(d.getMinutes()) +
                ':' +
                padZero(d.getSeconds()) +
                ' ' +
                padZero(d.getDate()) +
                '-' +
                padZero(d.getMonth() + 1) +
                '-' +
                d.getFullYear()
            );
        } catch (e) {
            return '"' + d + '"';
        }
    };


//##############################################################



//###############################################################

///              debug functions                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****  ///  debug functions //         *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/

    const updateLog = (message) => {
        setLogMessages(prevLogs => [...prevLogs, message]);
        console.log(message); // Also log to the console
    };
    const resetLog = () => {
        setLogMessages([]); // Clear the log messages
        updateLog("Log has been reset"); // Log the reset action
    };


    const logDevicesState = (deviceId) => {
        updateLog("find device: allocate - " + JSON.stringify(devices));
        updateLog("device id: " + devices.length);

        for (var i = 0; i < devices.length; i++) {
            updateLog("Comparing deviceId:" + deviceId + "with devices[i].deviceId:" + devices[i].deviceId);

            if (devices[i].deviceId === deviceId) {
                updateLog("find device: Found - " + JSON.stringify(devices[i]));


                return devices[i];
            }
        }
    };


//##############################################################



//###############################################################

///              JSX                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****  ///  JSX//                      *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/
//#####################################################################
    return (

        <div className="device-management-container">
            <h1>NFC-Emulator</h1>



            <div>
                {showTasksAndMessagesPopup && <div id="tasksAndMessagesPopup">Tasks and Messages Popup Content</div>}
                {showMessageModal && (
                    <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>

                    </div>
                )} {showConfirmModal && <ConfirmModal message={modalMessage} onConfirm={handleConfirmClose}
                                                      onCancel={() => setShowConfirmModal(false)}/>}
                {/* ... other divs with conditional rendering */}
            </div>


            <div className="mqtt-data-display">


                {/* Rest of your component */}
                {!isDeviceAdded && (
                    <div className="fixed-add-button-container">
                        <button className="buttonAdd" onClick={() => setShowAddDevicePopup(true)}>Add Device</button>
                    </div>
                )}
                {isDeviceAdded && (

                    <div className="fixed-delete-button-container">
                        <button className="delete-button" onClick={deleteDevices}>Delete</button>

                    </div>
                )}
                {showAddDevicePopup && (
                    <div id="addDevicePopup">
                        <div id="devicePopup" className="input-with-icon">
                            <select
                                id="addDeviceID"
                                className="device-id-select"
                                value={newDeviceId}
                                onChange={(e) => setNewDeviceId(e.target.value)}
                            >
                                <option value="">Select a Device ID</option>
                                {deviceIds.map((deviceId, index) => (
                                    <option key={index} value={deviceId}>
                                        {deviceId.slice(-4)}
                                    </option>
                                ))}
                            </select>




                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <button className="okButton" style={{marginRight: "0.5rem"}} onClick={onAddOk}>Add
                                    device
                                </button>
                                <button className="cancelButton" onClick={onAddCancel}>Cancel</button>

                            </div>
                        </div>

                    </div>
                )}


                {showAboutPopup && (
                    <div id="aboutpopup">
                        <div className="popupContent">
                            <p>Device ID: {aboutPopupData.deviceId} assigned to {aboutPopupData.workerName}</p>

                            <div>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Time</th>
                                        <th>Message</th>
                                        <th>Key UID</th>
                                        <th>Duration</th>
                                        <th>Result</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tableData[deviceId]?.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.time}</td>
                                            <td>{row.message}</td>
                                            <td>{row.keyUID}</td>
                                            <td>{row.duration}</td>
                                            <td>{row.result}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <div className="customize1">
                                    <label><strong className="popupText">Message :</strong></label>
                                    <input
                                        type="text"
                                        className="textzone"
                                        id="addNewMessageSubject"
                                        placeholder="message"
                                        value={messageSubject}
                                        onChange={e => setMessageSubject(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="customize1">
                                    <label><strong className="popupText">Key UID :</strong></label>
                                    <select
                                        className="textzone"
                                        id="addNewMessageBody"
                                        placeholder="14 digit UID"
                                        value={messageBody}
                                        onChange={e => setMessageBody(e.target.value)}
                                        required
                                    >
                                        {/* CS edit the values below to change the key list */}
                                        <option value="" disabled selected>Select your option</option>
                                        <option value="041D4D1ADC1190">Door access (041D4D1ADC1190)</option>
                                        <option value="043D4722DC1190">Computer access (043D4722DC1190)</option>
                                    </select>
                                </div>
                                <div className="customize1">
                                    <label><strong className="popupText">Active duration :</strong></label>
                                    <input
                                        type="text"
                                        className="textzone"
                                        id="addNewMessageFrom"
                                        placeholder="time in seconds"
                                        value={messageFrom}
                                        onChange={e => setMessageFrom(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="cancelAndOk">
                                    <button className="buttonAdd"  disabled={!messageBody.trim() || !messageSubject.trim() || !messageFrom.trim()} onClick={() => handleSendKey(deviceId)}>Send key
                                    </button>
                                    <button onClick={() => resetReturn(deviceId)}
                                            style={{backgroundColor: "#c9c9c9"}}>Reset table
                                    </button>
                                    {/*<button onClick={leaveAbout} style={{backgroundColor: "#f44336"}}>Cancel</button>*/}

                                </div>
                                <div className="closeButtonContainer">

                                    <button onClick={leaveAbout} style={{backgroundColor: "#f44336"}}>Close</button>

                                </div>

                            </div>


                        </div>
                    </div>

                )}




                {/* Debug section */}


                <table className="devices-table">
                    <tbody>
                    {devices.map(device => (
                        <React.Fragment key={device.deviceId}>
                            <tr>
                                <th>--Device--</th>
                                <td>{device.deviceId}</td>
                            </tr>

                            <tr>
                                <th>Status</th>
                                <td>
                                    {device.allocatedTo
                                        ? `${workerStatuses[device.allocatedTo] || 'working'} @ [${Location}]`
                                        : `@ [${Location}]`}
                                </td>
                            </tr>

                            <tr>
                                <th>To</th>
                                <td>{VisitorId || ''}</td>
                            </tr>
                            <tr>
                                <th>Request</th>
                                <td>
                                    {VisitorId &&
                                        <input className="buttonOther" type="button" value="Key requests" onClick={() => onAbout(device.deviceId)}/>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Last Seen</th>
                                <td>{device.lastSeenAt}</td>
                            </tr>
                            <tr>
                                <th>Return</th>
                                <td>
                                    {VisitorId &&
                                        <input className="buttonOther" type="button" value="Return" onClick={() => onReturnOk(device.deviceId)}/>
                                    }
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                    </tbody>
                </table>










            </div>
        </div>

    );
}

export default NFCui;
