import React from "react"
import {Link} from "react-router-dom"
import {Button} from "reactstrap"


function App() {
    return (
        <>
            <div>
                <div className='' style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <h1>1. Check-In</h1>

                    <div style={{margin: '1.0rem 0'}}>
                        <p style={{marginBottom: '0'}}>Check in and get your device customized</p>
                        <Link to="/allocate">
                            <Button className='primaryButton'>Click to start Allocation</Button>
                        </Link>
                    </div>
                    <div style={{margin: '1.5rem 0'}}>
                        <p style={{marginBottom: '0', fontSize: '1rem'}}>or explore the IES Suite</p>
                        <Link to="/suite">
                            <Button className='secondaryButton'>Explore IES Suite</Button>
                        </Link>
                    </div>

                </div>
            </div>


        </>
    )
}

export default App