import React from "react";
import style from './Header.css';

function Header() {

    return (
        <>
            <div className='headerContainer'>

                <div className='header'>
                    <div className='headerTitle'>
                        <img height={45} className={'image'} src="infrafonKioskLogo.png"/>
                        <div className={'buttonContainer'}>
                            <a className={'backbutton'} href={'/suite'}>IES-Suite</a>
                            <a className={'backbutton'} href={'/'}>Check-in</a>
                        </div>
                    </div>


                </div>


            </div>

        </>
    );
}

export default Header;
