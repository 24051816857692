import React from "react"
import { useState } from "react"
import Webcam from "react-webcam"
import { useWizard } from 'react-use-wizard'
import { Button, Container, Row, Col, Alert } from "reactstrap"


const AllocationWizardPhoto = ({ image, setImage }) => {
    const { previousStep, nextStep } = useWizard()
    const [errorMessage, setErrorMessage] = useState({ 'visible': false, message: '' })

    /**
     * Webcam stuff
     */
    const videoConstraints = {
        width: 320,
        height: 320,
        facingMode: "user"
    }
    const webcamRef = React.useRef(null)
    const capture = React.useCallback(
        () => {
            convertPicture(webcamRef.current.getScreenshot())
        },
        [webcamRef]
    )

    const showAlert = (message) => {
        setErrorMessage({ visible: true, message: message })
        setTimeout(function () { setErrorMessage({ visible: false, message: '' }) }, 3000)
    }

    const convertPicture = async (pictureBase64) => {
        const requestOptions = {
            //mode: 'no-cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'image_data': pictureBase64,
                'new_width': 300,
                'new_height': 300
            })
        }

        let res = undefined
        try {
            res = await fetch('https://imgconvert.infrafon.cloud/img-to-base64', requestOptions)
        }
        catch (e) {
            showAlert('Communication error')
        }
        const data = await res.json()
        setImage(data.image_data)
        nextStep()
    }

    return (

            <div className='shell' >


                    <span className='formHeading'>Take a picture</span>

                            <Alert color="danger" isOpen={errorMessage.visible}>
                                {errorMessage.message}
                            </Alert>
                            <Webcam
                                audio={false}
                                height={320}
                                screenshotFormat="image/jpeg"
                                width={320}
                                videoConstraints={videoConstraints}
                                ref={webcamRef}>
                            </Webcam>




                            <div className={'flex-row'}>
                                <Button className={'primaryButton'} onClick={previousStep}>Previous</Button>
                                <Button className={'primaryButton'} onClick={capture}>Take Picture</Button>
                                {image &&
                                    <Button className={'primaryButton'} onClick={nextStep} >Next</Button>
                                }
                            </div>

            </div>
    )
}

export default AllocationWizardPhoto