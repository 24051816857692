import { useState } from "react"
import { useWizard } from 'react-use-wizard'
import { useNavigate } from "react-router-dom"
import { Button, Container, Row, Col, Alert } from "reactstrap"
import mqtt from "mqtt";

const AllocationWizardAllocate = ({ image, deviceId, setImage, setDeviceId, userData }) => {
  const navigate = useNavigate()
  const { previousStep } = useWizard()
  const [errorMessage, setErrorMessage] = useState({ visible: false, message: '' })
  const [successMessage, setSuccessMessage] = useState({ visible: false })

  const mqttClient = mqtt.connect("wss://mqtt.infrafon.cloud:8443");
  mqttClient.on("connect", function() {
    console.log("connected to MQTT");
  });

  async function finish() {
    allocate().then((ok) => {
      if (ok) {
        setSuccessMessage({ visible: true });
        setTimeout(function () {
          mqttClient.publish(`ife/${userData.entity}/device/app-down/${deviceId}`, '{ "app": { "pager": { "data": { "msg": "Welcome to Infrafon!", "from": "Infrafon" }, "actions": { "gotoPage": "1" }}}}', { false: true, qos: 1});
          mqttClient.publish(`ife/${userData.entity}/device/app-down/${deviceId}`, '{ "app": { "dev": { "buzzer": { "song": "V101Z910V201Z910" }, "vibration": { "song": "V10S01V10S01"} }}}', { false: true, qos: 1});
          setSuccessMessage({ visible: false })
          setImage()
          setDeviceId()
          navigate("/", { replace: true })
        }, 90000);
      }
    })
  }

  const showAlert = (message) => {
    setErrorMessage({ visible: true, message: message })
    setTimeout(function () { setErrorMessage({ visible: false, message: '' }) }, 3000)
  }

  const allocate = async () => {
    return allocateStage1()
  }

  const allocateStage1 = async () => {
    console.log("name is", userData.name);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.jwt
      },
      body: JSON.stringify({
        'visitor': userData.name,
        'apps': {
          'home': {
            'data': {
              'name': userData.name,
              'department': 'Demo Event',
              'journey': 'IES Kiosk setup',
              'idphoto': image
            }
          },
          'pharma_picklist': {
            'data': {
            }
          },
          'pharmacy_quest': {
            'data': {
            }
          },
          'pager': {
            'data': {
            }
          },
        },
        'device': deviceId
      })
    }
    let res = undefined
    try {
      res = await fetch(`https://api.infrafon.cloud/kmgr/rest/v1/kiosk/${userData.entity}/${userData.kiosk}/allocate`, requestOptions)
      if (!res.ok) {
        showAlert('Server communication error')
        return false
      }
      if (res.status >= 400) {
        showAlert('Server communication error')
        return false
      }
    }
    catch (e) {
      showAlert('Server communication error')
      return false
    }
    const data = await res.json()
    return true
  }

  return (
    <div className="">
      <Row className="align-items-center h-100">
        <Col xs="1" sm="4" />
        <Col xs="11" sm="8">
          <Alert color="danger" isOpen={errorMessage.visible}>
            {errorMessage.message}
          </Alert>
          <Alert isOpen={successMessage.visible}>
            <h4 className="alert-heading">
              Well done!
            </h4>
            <p>
              Aww yeah, you successfully have registered your Infrafon!
            </p>
            <p>
              Please push the QR code on the display to get the Dataviews.
            </p>
            <p>
              Stay on this screen until it disappears.
            </p>
          </Alert>
          {(!successMessage.visible) &&
              <Row>
                <Row>
                  <h2>Dear {userData.username}, do you want to register now?</h2>
                </Row>
                <Row>
                  <Button color="info" onClick={() => previousStep()}>Previous</Button>
                  <Button color="info" onClick={finish}>Yes, give me my Infrafon!</Button>
                </Row>
              </Row>
          }
        </Col>
      </Row>
    </div>
  )
}

export default AllocationWizardAllocate