import React, { useState } from 'react';
import QRCodeScanner from '../components/QRCodeScanner';
import { useWizard } from 'react-use-wizard';
import { Button, Alert } from 'reactstrap';

const AllocationWizardQRScan = ({ deviceId, setDeviceId }) => {
    const { previousStep, nextStep } = useWizard();
    const [errorMessage, setErrorMessage] = useState({ visible: false, message: '' });
    let isLoading = false;

    const isJSON = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const showAlert = (message) => {
        setErrorMessage({ visible: true, message });
        setTimeout(() => {
            setErrorMessage({ visible: false, message: '' });
        }, 3000);
    };

    const verifyDevice = (decodedText) => {
        if (isLoading) {
            console.log('Already verifying device id');
            return;
        }

        isLoading = true;
        findDevice(decodedText).then(() => {
            isLoading = false;
        });
    };

    const findDevice = async (decodedText) => {
        if (!isJSON(decodedText)) {
            showAlert(`${decodedText}Not a valid Infrafon device QR code`);
            return;
        }

        console.log('QR code read');

        let res;
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'devicekey whatsoever',
                },
            };
            res = await fetch(`https://api.infrafon.cloud/ife/rest/v1/device/${JSON.parse(decodedText).devid}`, requestOptions);
        } catch (e) {
            showAlert('Server communication error');
            return;
        }

        console.log(res);
        if (res.ok) {
            const data = await res.json();
            console.log(data);
            if (!deviceId) {
                const state = data.data.state;
                if (state.current === 'DEP_ALLOCED' || state.current === 'DEP_ALLOCING') {
                    showAlert('Device is already allocated!');
                } else {
                    setDeviceId(data.data.name);
                    nextStep();
                }
            }
        } else {
            if (res.status === 404) {
                showAlert('Infrafon device is not registered!');
            } else {
                showAlert('Server communication error');
            }
        }
    };

    const qrError = (error) => {
        showAlert('QR Scanner error: ' + error.message);
    };

    return (
        <div className="shell">
            <span className={'formHeading'}>Scan the QR-Code on the device</span>
            <div className={'QR-field'}>
                <Alert color="danger" isOpen={errorMessage.visible}>
                    {errorMessage.message}
                </Alert>
                <QRCodeScanner
                    qrCodeSuccessCallback={verifyDevice}
                    qrCodeErrorCallback={qrError}
                />
            </div>

            <div className={'flex-row'}>
                <Button color="info" onClick={previousStep}>Previous</Button>
                {deviceId &&
                    <Button color="info" onClick={nextStep}>Next</Button>
                }
            </div>
        </div>
    );
};

export default AllocationWizardQRScan;
