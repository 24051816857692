import { useReducer } from "react";
import { useWizard } from 'react-use-wizard';
import {
  Alert,
  Form,
  FormGroup,
  Input,
  Button,
  Container,
  Row,
  Col
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {login, kiosks} from "../api/ies.api";
import sysmsg from "../config/sysmsg.json";

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value
  }
}

const AllocationWizardData = ({ userData, setUserData }) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useReducer(formReducer, userData)
  const { handleStep, nextStep } = useWizard()
  const [erroneousUser, setErroneousUser] = useState(false)
  const [erroneousName, setErroneousName] = useState(false)
  const [errorMessage, setErrorMessage] = useState({visible: false, message: ''})

  const cancel = () => {
    navigate('/');
  }

  handleStep(() => {
    if (!formData.name) {
      setErroneousName(true);
    }
    setUserData(formData);
  })

  const showAlert = (message) => {
    setErrorMessage({visible: true, message: message});
    setTimeout(function () {
      setErrorMessage({visible: false, message: ''})
    }, 3000);
  }

  const authenticate = event => {
    event.preventDefault();
    if ((!formData.username) || (!formData.password) || (!formData.name)) {
      setErroneousUser(true);
      showAlert(sysmsg.MISSING_DATA_ERROR_TEXT);
    }
    login(formData.username, formData.password).then((res) => {
      if (!res.success) {
        return showAlert(sysmsg.LOGIN_ERROR_TEXT);
      }
      let authData = res.data.data;
      formData.entity = authData.entity;
      formData.jwt = authData.access_token;
      kiosks(formData).then((res) => {
        let kioskData = res.data.data;
        if (kioskData.length === 0) {
          return showAlert(sysmsg.LOGIN_ERROR_TEXT);
        }
        formData.kiosk = kioskData[0].name;
        return nextStep();
      });
    });
  }

  const handleChange = event => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  }

  return (
    <>


          <Alert color="danger" isOpen={errorMessage.visible}>
            {errorMessage.message}
          </Alert>

          <div>
            <span className='formHeading'>Please enter your IES credentials</span>
            <Form>
              <FormGroup>
                <label htmlFor="name"Please enter your credentials></label>
                <Input
                    style={{ color: "#2A3446", borderRadius: '.25rem'}}
                    id="username"
                    name="username"
                    placeholder="Enter your username"
                    value={formData.username || ''}
                    type="text"
                    invalid={erroneousUser}
                    onChange={handleChange}
                ></Input>
              </FormGroup>
              <FormGroup>
                <Input
                    style={{ color: "#2A3446", borderRadius: '.25rem'}}
                    id="password"
                    name="password"
                    placeholder="Enter your password"
                    value={formData.password || ''}
                    type="password"
                    invalid={erroneousUser}
                    onChange={handleChange}
                ></Input>
              </FormGroup>
              <FormGroup>
                <Input
                    style={{ color: "#2A3446", borderRadius: '.25rem'}}
                    id="name"
                    name="name"
                    placeholder="Enter your diplay name"
                    value={formData.name || ''}
                    type="text"
                    invalid={erroneousName}
                    onChange={handleChange}
                ></Input>
              </FormGroup>
              <Button color="info" onClick={cancel}>Cancel</Button>
              <Button color="info" onClick={authenticate}>Next</Button>
            </Form>
          </div>

    </>
  )
}

export default AllocationWizardData