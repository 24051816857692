import NFCuiadd from './NFCuiadd';
import NFCscan from './NFCscan';
import { Wizard } from 'react-use-wizard';
import { useState } from 'react';

const NFCWizard = () => {
  const [newDeviceId, setNewDeviceId] = useState('');

  return (
    <main>
      <Wizard>
        {/* First step: NFCuiadd */}
        <NFCuiadd newDeviceId={newDeviceId} setNewDeviceId={setNewDeviceId} />

        {/* Second step: NFCscan */}
        <NFCscan newDeviceId={newDeviceId}  />
      </Wizard>
    </main>
  );
};

export default NFCWizard;
