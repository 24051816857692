import React, { useRef, useCallback } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';

const QRCodeScanner = ({ qrCodeSuccessCallback, qrCodeErrorCallback }) => {
    const qrScannerRef = useRef(null);

    const handleScan = useCallback(
        (result) => {
            if (result) {
                qrCodeSuccessCallback(result[0].rawValue)
            } else {
                console.warn('No result from QR Code scan');
                qrCodeErrorCallback(new Error('No result from QR Code scan'));
            }
        },
        [qrCodeSuccessCallback, qrCodeErrorCallback]
    );

    const handleError = useCallback(
        (error) => {
            console.error('QR Code scan error:', error);
            qrCodeErrorCallback(error);
        },
        [qrCodeErrorCallback]
    );

    return (
        <Scanner
            ref={qrScannerRef}
            allowMultiple={true}
            onScan={handleScan}
            onError={handleError}
            constraints={{ facingMode: 'environment' }} // Adjust if needed
            style={{ width: '100%', height: '100%' }}
        />
    );
};

export default QRCodeScanner;
