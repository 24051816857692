import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {BrowserRouter, Routes, Route} from "react-router-dom"
import AllocationWizard from './pages/AllocationWizard'
import "assets/css/bootstrap.min.css"
import "assets/css/now-ui-kit.css"
import Home from "./pages/Home";
import Header from "./components/Headers/Header";
import Footer from "./components/Footers/Footer"
import NFCui from "./pages/NFCui";
import NFCscan from "./pages/NFCscan"
import NFCuiadd from "./pages/NFCuiadd"
import NFCWizard from './pages/NFCWizard';

// import "assets/css/now-ui-kit.min.css";
// import "assets/css/now-ui-kit.css.map";
//import "assets/demo/demo.css"

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <>
        <Header></Header>
        <div className={'frame'}>
            <div className={'line'}></div>
            <div className={'blueStripe'}></div>
            <div className={"container"}>

                {/*<div id="logo">*/}
                {/*  <img src="smart-factory-logo.png" />*/}
                {/*</div>*/}
                <>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<App/>}/>
                            <Route path="allocate" element={<AllocationWizard/>}/>
                            <Route path="suite" element={<Home/>}/>
                            <Route path="nfc" element={<NFCui/>}/>
                            <Route path="scan" element={<NFCWizard/>}/>
                            <Route path="add" element={<NFCuiadd/>}/>
                            <Route path="nfc-wizard" element={<NFCWizard/>}/> {/* Add this line */}

                        </Routes>
                    </BrowserRouter>
                </>
            </div>
        </div>
        <Footer></Footer>
    </>
    //<React.StrictMode>
    //</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
