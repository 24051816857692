import AllocationWizardPhoto from './AllocationWizardPhoto'
import AllocationWizardQRScan from './AllocationWizardQRScan'
import AllocationWizardAllocate from "./AllocationWizardAllocate"
import AllocationWizardData from './AllocationWizardData'
import {Wizard} from 'react-use-wizard'
import {useState} from "react"

const AllocationWizard = () => {
    const [userData, setUserData] = useState({});
    const [image, setImage] = useState();
    const [deviceId, setDeviceId] = useState();

    return (
        <main>
            <Wizard>
                <AllocationWizardData userData={userData} setUserData={setUserData}/>
                <AllocationWizardPhoto image={image} setImage={setImage}/>
                <AllocationWizardQRScan deviceId={deviceId} setDeviceId={setDeviceId}/>
                <AllocationWizardAllocate image={image} deviceId={deviceId} setImage={setImage}
                                          setDeviceId={setDeviceId} userData={userData}/>
            </Wizard>
        </main>
    )
}

export default AllocationWizard