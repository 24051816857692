/*eslint-disable*/
import React from "react";
import styles from "./Footer.css"
// reactstrap components
import { Container } from "reactstrap";

function TransparentFooter() {
  return (
    <footer className="footer-bar">
      <div className={'footer-content'}>
        <div className={'axxelia-div'}>
            <span>powered by</span>
            <img height={40} width={100} src="smart-factory-logo.png"/>
        </div>
      </div>
    </footer>
  );
}

export default TransparentFooter;
